import BasketType from "../../social-supermarket/model/BasketType"
import ShippingMethod from "../../social-supermarket/model/ShippingMethod"
import { getCheapestShipping } from "../../utils/cart"
import { safeHeDecode } from "../../social-supermarket/util/generalUtil"
import { AttributeType, BasketItemType } from "social-supermarket-model"
import { parsePrice } from "social-supermarket-model"

export const mapBasket = (cart: any, shippingMethods: any): BasketType => {
  const shippingMethod = mapShippingMethod(getCheapestShipping(shippingMethods))
  const totalTax = parsePrice(cart.totals.total_tax) + shippingMethod.tax
  return {
    hash: cart.cart_hash,
    key: cart.cart_key,
    subTotal: getSubTotal(cart),
    subTotalTax: parsePrice(cart.totals.subtotal_tax),
    discountTotal: getDiscountTotal(cart),
    discountTax: cart.totals.discount_tax,
    items: cart.items.map(mapBasketItem),
    shippingMethod,
    total: parsePrice(cart.totals.total) + shippingMethod.total,
    totalTax,
    coupons: cart.coupons.map(({ coupon }) => ({ code: coupon })),
  }
}

export const mapBasketItem = (item: any): BasketItemType => {
  const itemPrice = parsePrice(item.price)
  const lineTotal = parsePrice(item.line_subtotal) + parsePrice(item.line_subtotal_tax)
  const priceCharged = lineTotal / item.quantity

  return {
    key: item.key,
    deliveryDate: item.deliveryDate,
    product: {
      productId: item.product_id,
      slug: item.slug,
      name: safeHeDecode(item.product_name || ""),
      sku: item.sku,
      price: priceCharged,
      originalPrice: priceCharged != itemPrice ? itemPrice : undefined,
      stockStatus: item.stock_status.status,
      shippingClass: item.shippingClass,
      vendor: {
        name: item.vendorName,
        slug: item.vendorSlug,
        acceptsGiftNotes: item.vendorAcceptsGiftNotes,
        deliveryBusinessDays: item.vendorDeliveryBusinessDays,
        deliveryNotice: item.vendorDeliveryNotice,
        lastEventDate: item.vendorLastEventDate,
        lastShipmentDate: item.vendorLastShipmentDate,
        resumeShipmentDate: item.vendorResumeShipmentDate,
      },
      brand: {
        name: item.brandName,
        slug: item.brandSlug,
      },
      image: {
        src: item.product_image,
        alt: "",
      },
      soonestDeliveryDate: item.soonestDeliveryDate,
      availableCountries: ["GB"],
      hasStock: true,
    },
    quantity: item.quantity,
    variant: item.variation_id
      ? {
          variantId: item.variation_id,
          sku: item.sku,
          attributes: Object.keys(item.variation_data).map(key => ({
            name: key,
            value: item.variation_data[key],
          })),
          image: {
            src: item.product_image,
            alt: "",
          },
          price: priceCharged,
        }
      : undefined,
    giftCard: item.giftCardEmail
      ? {
          name: item.giftCardName,
          email: item.giftCardEmail,
          schedule: item.giftCardSchedule,
          date: item.giftCardDate,
          note: item.giftCardNote,
        }
      : undefined,
    type: item.type,
    dimensions: mapDimensions(item.dimensions),
  }
}

export const mapShippingMethod = (method: any): ShippingMethod => {
  return {
    id: method.instance_id,
    methodId: method.method_id,
    key: method.key,
    label: method.label,
    total: mapShippingTotal(method),
    tax: mapShippingTax(method),
  }
}

export const mapShippingTotal = (method: any): number => {
  const methodShipping = method?.html?.match(/\d+(?:\.\d+)?/g)
  return parsePrice(methodShipping && methodShipping.length > 0 ? methodShipping[0] : 0)
}

export const mapShippingTax = (method: any): number => {
  return parseFloat(`${method?.taxes ? Object.values(method.taxes)[0] : 0}`)
}

export const getDiscountTotal = (cart: any): number => {
  const { totals } = cart
  return parsePrice(totals.discount_total) + parsePrice(totals.discount_tax)
}

export const getSubTotal = (cart: any): number => {
  const { totals } = cart
  return parsePrice(totals.subtotal) + parsePrice(totals.subtotal_tax)
}

export const mapAttributes = (attributes: AttributeType[]): object => {
  const result = {}
  attributes.forEach(attribute => {
    attributes[attribute.name] = attribute.value
  })
  return result
}

export const mapDimensions = dimensions => {
  const height = dimensions?.height
  const width = dimensions?.width
  const length = dimensions?.length
  return {
    height: parseFloat(height),
    width: parseFloat(width),
    length: parseFloat(length),
  }
}
