import * as React from "react"
import { BasketContext } from "../../context/BasketProvider"
import styled from "styled-components"
import { Icon, Progress, Placeholder } from "semantic-ui-react"
import { useContext } from "react"
import ShippingProgress from "./ShippingProgress"
import GiftCardType from "../../model/GiftCardType"
import { formatPrice } from "social-supermarket-model"

const Container = styled.div`
  display: flex;
`
const PlaceholderContainer = styled.div`
  min-height: 85px;
`
const ProgressBar = styled.div`
  flex: 1;
`
const Totals = styled.div`
  width: 200px;
  display: flex;
  font-size: 0.9em;
`
const TotalsLeft = styled.div`
  flex: 1;
  text-align: right;
`
const TotalsRight = styled.div`
  flex: 1;
  text-align: right;
`

interface Props {
  showCountdown: boolean
}

const BasketTotals = ({ showCountdown }: Props) => {
  const {
    isLoading,
    giftCards,
    basket: { shippingMethod, discountTotal, subTotal, total },
  } = useContext(BasketContext)

  const giftCardUse = getGiftCardUse(giftCards, total)
  const grandTotal = total - giftCardUse

  return isLoading && !subTotal ? (
    <PlaceholderContainer>
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    </PlaceholderContainer>
  ) : (
    <Container>
      <ProgressBar>
        {(shippingMethod.total > 0 || subTotal > 100) && grandTotal > 0 && showCountdown && (
          <ShippingProgress subTotal={subTotal} />
        )}
      </ProgressBar>

      <Totals>
        <TotalsLeft>
          <div>Subtotal</div>
          {discountTotal > 0 && <div>Discount</div>}
          <div>Delivery</div>
          {giftCardUse > 0 && <div>Gift Card</div>}
          <div>
            <b>Total</b>
          </div>
        </TotalsLeft>
        <TotalsRight>
          <div>{formatPrice(subTotal)}</div>
          {discountTotal > 0 && <div>({formatPrice(discountTotal)})</div>}
          <div>{formatPrice(shippingMethod.total)}</div>
          {giftCardUse > 0 && <div>({formatPrice(giftCardUse)})</div>}
          <div>
            <b>{formatPrice(grandTotal)}</b>
          </div>
        </TotalsRight>
      </Totals>
    </Container>
  )
}

export const getGiftCardUse = (giftCards: GiftCardType[], total: number): number => {
  const giftCardBalance = giftCards.reduce((prev, current) => prev + current.balance, 0)
  return giftCardBalance > total ? total : giftCardBalance
}

export const updateGiftCardUsage = (giftCards: GiftCardType[], total: number): GiftCardType[] => {
  let used = 0
  const newGiftCards = []
  giftCards.forEach(giftCard => {
    if (used < total) {
      const remaining = total - used
      const giftCardUsage = remaining < giftCard.balance ? remaining : giftCard.balance
      used += giftCardUsage
      newGiftCards.push({ ...giftCard, usage: giftCardUsage })
    }
  })
  return newGiftCards
}

export default BasketTotals
