import * as googleTracker from "./uaGoogleTracker"
import * as ga4GoogleTracker from "./ga4GoogleTracker"
import * as facebookTracker from "./facebookTracker"
import * as tapfilliateTracker from "./tapfilliateTracker"
import * as algoliaTracker from "./algoliaTracker"
import * as awinTracker from "./awinTracker"
import BasketType from "../model/BasketType"
import { isRunTime } from "../util/generalUtil"
import { CheckoutFormStateType } from "../pages/checkout/CheckoutPage"
import { ProductType, VariantType } from "social-supermarket-model"

export const prePageLoad = () => {
  googleTracker.prePageLoad()
}

export const productImpressions = (
  index: number,
  products: ProductType[],
  collectionName: string
) => {
  if (isRunTime()) {
    // googleTracker.productImpressions(index, products, collectionName)
    ga4GoogleTracker.productImpressions(index, products, collectionName)
  }
}

export const loadMoreProducts = () => {
  if (isRunTime()) {
    googleTracker.loadMoreProducts()
  }
}

export const productClick = (
  product: ProductType,
  position: number,
  queryId?: string,
  collectionName?: string
) => {
  if (isRunTime()) {
    // googleTracker.productClick(product, position)
    ga4GoogleTracker.productClick(product, position, collectionName)
    algoliaTracker.productClick(product, position, queryId)
  }
}

export const productView = (product: ProductType) => {
  if (isRunTime()) {
    // googleTracker.productView(product)
    ga4GoogleTracker.productView(product)
    facebookTracker.productView(product)
  }
}

export const variantSelected = (variant: VariantType) => {
  if (isRunTime()) {
    // googleTracker.variantSelected(variant)
  }
}

export const addToBasket = (
  product: ProductType,
  variant: VariantType,
  quantity: number,
  queryId?: string
) => {
  if (isRunTime()) {
    // googleTracker.addToBasket(product, variant, quantity)
    ga4GoogleTracker.addToBasket(product, variant, quantity)
    facebookTracker.addToBasket(product, variant, quantity)
    algoliaTracker.addToBasket(product, queryId)
  }
}

export const removeFromBasket = (product: ProductType, variant: VariantType) => {
  if (isRunTime()) {
    // googleTracker.removeFromBasket(product)
    ga4GoogleTracker.removeFromBasket(product, variant)
  }
}

export const startCheckout = (basket: BasketType) => {
  if (isRunTime()) {
    // googleTracker.startCheckout(basket)
    ga4GoogleTracker.startCheckout(basket)
  }
}

export const orderSuccess = (
  orderId: number,
  basket: BasketType,
  formState: CheckoutFormStateType
) => {
  if (isRunTime() && !hasAdminCoupon(basket)) {
    // googleTracker.orderSuccess(orderId, basket)
    ga4GoogleTracker.orderSuccess(orderId, basket)
    facebookTracker.orderSuccess(orderId, basket, formState)
    tapfilliateTracker.orderSuccess(orderId, basket)
    awinTracker.orderSuccess(orderId, basket)
  }
}

export const orderSuccessPage = () => {
  if (isRunTime()) {
    awinTracker.orderSuccessPage()
  }
}

export const corporateEnquiry = (value: number) => {
  if (isRunTime()) {
    console.log("tracking enquiry with value", value)
    ga4GoogleTracker.corporateEnquiry(value)
    googleTracker.corporateEnquiry(value)
  }
}

export const trackLeadMagnetConversion = (value: number) => {
  if (isRunTime()) {
    console.log("tracking conversion with value", value)
    ga4GoogleTracker.conversion("LeadMagnet", value)
    googleTracker.conversion("LeadMagnet", value)
  }
}

export const trackConversion = (eventName: string, value: number) => {
  if (isRunTime()) {
    console.log("tracking conversion with value", value)
    ga4GoogleTracker.conversion(eventName, value)
    googleTracker.conversion(eventName, value)
  }
}

export const modalOpened = () => {
  if (isRunTime()) {
    ga4GoogleTracker.modalOpened()
    googleTracker.modalOpened()
  }
}

export const platformBookADemo = () => {
  if (isRunTime()) {
    ga4GoogleTracker.platformBookADemo()
    googleTracker.platformBookADemo()
    facebookTracker.lead()
  }
}

export const platformGetStartedButton = () => {
  if (isRunTime()) {
    ga4GoogleTracker.platformGetStarted()
    googleTracker.platformGetStarted()
  }
}

const hasAdminCoupon = (basket: BasketType): boolean => {
  return basket.coupons.some(coupon => coupon.code === "7cwhjenv")
}
