import BasketType, { DEFAULT_BASKET } from "./BasketType"
import GiftCardType from "./GiftCardType"
import { CheckoutFormStateType } from "../pages/checkout/CheckoutPage"
import { Dispatch, SetStateAction } from "react"
import { ProductType, VariantType } from "social-supermarket-model"

export type BasketContextType = {
  basket: BasketType
  giftCards: GiftCardType[]
  suggestions: ProductType[]
  isLoading: boolean
  isOpen: boolean
  giftNote: string
  deliveryNote: string
  subscribe: boolean
  basketInitialised: boolean
  toggleSubscribe: () => void
  updateGiftNote: (giftNote: string) => void
  updateDeliveryNote: (deliveryNote: string) => void
  updateProduct: (
    product: ProductType,
    variant: VariantType,
    basketItemKey: string,
    quantity: number,
    meta: object,
    refresh: boolean
  ) => void
  toggle: (open?: boolean) => void
  clearBasket: () => void
  addProduct: (
    product: ProductType,
    variant: VariantType,
    quantity: number,
    meta: object,
    refresh?: boolean
  ) => Promise<void>
  removeProduct: (
    basketItemKey: string,
    product: ProductType,
    refresh?: boolean,
    variant?: VariantType
  ) => Promise<void>
  increaseQuantity: (basketItemKey: string) => Promise<void>
  updateQuantity: (basketItemKey: string, quantity: number, refresh?: boolean) => Promise<void>
  reduceQuantity: (basketItemKey: string) => Promise<void>
  applyCoupon: (code: string, setError: (error: string) => void) => Promise<void>
  removeCoupons: () => Promise<void>
  removeGiftCards: () => Promise<void>
  fetchBasket: () => Promise<void>
  checkoutFormState: CheckoutFormStateType
  setCheckoutFormState: Dispatch<SetStateAction<CheckoutFormStateType>>
  queryId: string
  setQueryId: (id: string) => void
}

export const DEFAULT_BASKET_CONTEXT_TYPE: BasketContextType = {
  basket: DEFAULT_BASKET,
  suggestions: [],
  isLoading: true,
  isOpen: false,
  giftCards: [],
  giftNote: "",
  deliveryNote: "",
  subscribe: false,
  basketInitialised: false,
  updateProduct: () => {},
  updateGiftNote: () => {},
  updateDeliveryNote: () => {},
  toggle: () => {},
  toggleSubscribe: () => {},
  addProduct: () => {
    return Promise.resolve()
  },
  removeProduct: () => Promise.resolve(),
  clearBasket: () => {},
  increaseQuantity: () => Promise.resolve(),
  reduceQuantity: () => Promise.resolve(),
  updateQuantity: () => Promise.resolve(),
  applyCoupon: () => Promise.resolve(),
  removeCoupons: () => Promise.resolve(),
  removeGiftCards: () => Promise.resolve(),
  fetchBasket: () => Promise.resolve(),
  checkoutFormState: null,
  setCheckoutFormState: () => null,
  queryId: "",
  setQueryId: () => null,
}
